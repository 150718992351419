import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import getLogoOrDefault from '../../components/common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Modal from '../../components/Modal'
import {
  Risk,
  STRATEGY_INDEX_LOWER,
  STRATEGY_POSITION_ID_THRESHOLD,
  StrategyInfo,
  StrategyTag,
  Tags,
  useStrategyAmounts,
} from './StrategiesList'
import {
  Asset,
  AssetList,
  AssetTitle,
  Expiration,
  FilterSelect,
  FilterSelector,
  StyledAutoAwesomeIcon,
  StyledNoneIcon,
  SubmitButton,
  SubmitButtonWrapper,
  Tag,
  TagsWrapper,
  THREE_DAYS_MILLIS,
  TokenLogo,
  Yield,
} from './index'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { useDolomiteBalancesWithLoadingIndicator, useTokenBalances } from '../../state/wallet/hooks'
import { formatAmount } from '../../utils/formatAmount'
import { useActiveWeb3React } from '../../hooks'
import BorrowTradeDetails from '../Borrow/BorrowPosition/BorrowTradeDetails'
import { useGetZapExactTokensForTokensParams } from '../../hooks/useGetZapParams'
import { tryParseAmount } from '../../state/trade/hooks'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import { BalanceCheckFlag, CurrencyAmount, Rounding } from '@dolomite-exchange/v2-sdk'
import { NewValue, NewValueWrapper } from '../Borrow/BorrowPosition/BorrowExpandedContent'
import {
  ChainId,
  DEFAULT_MIN_COLLATERALIZATION,
  ONE_FRACTION,
  ZERO_ADDRESS,
  ZERO_FRACTION,
  ZERO_PERCENT,
} from '../../constants'
import ArrowRight from '@material-ui/icons/ArrowForward'
import { useZapExactTokensForTokens, ZapEither, ZapEventType, ZapType } from '../../hooks/useExecuteZap'
import ReactGA from 'react-ga'
import JSBI from 'jsbi'
import { ethers } from 'ethers'
import { useUserLifetimeBorrowPositionCount } from '../../types/userLifetimeData'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import {
  getStrategyInterestRate,
  useHistoricalInterestRateSeries,
  useStrategyInterestRate,
} from '../../hooks/useNetInterestRate'
import { useInterestRateData } from '../../types/interestRateData'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar'
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar'
import DepositWithdrawModal from '../../components/DepositWithdrawModal'
import ZapModal from '../Borrow/ZapModal'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { YieldTooltip } from '../../components/Balances/BalancesPanel'
import calculateAprToApy from '../../utils/calculateAprToApy'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { InterestRatePart } from '../../types/InterestRatePart'
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import dayjs from 'dayjs'
import getNewInterestRateWithSlippage from '../../utils/getNewInterestRateWithSlippage'
import { useDolomiteMarginTokenAddressToIdMap } from '../../hooks/useDolomiteMarginProtocol'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { RefreshFrequency } from '../../state/chain/hooks'
import { calculateStrategyHealth } from '../../utils/calculateBorrowPositionHealth'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useAddExternalTokensToDolomiteData } from '../../utils/externalTokens'
import { useDolomiteMarginContract } from '../../hooks/useContract'
import { getPositionHealth, PositionHealthTooltip } from '../Borrow/BorrowPositionRow'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import { PageSizeContext } from '../App'
import Checkmark from '@material-ui/icons/Check'
import { GmFeeExplainer } from '../Borrow/OpenNewBorrow'
import { useOpenBorrowPosition } from '../../hooks/useBorrowPositionProtocol'
import { BorrowPosition } from '../../types/borrowPositionData'
import { InfinityWrapper, StrategyHealth } from './ManageStrategyModal'
import { ChartTypes } from '../../components/Balances/TokenRow'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'

export enum StrategyStage {
  OPEN = 0,
  ZAP = 1,
  COMPLETE = 2,
}

export const ModalInner = styled.div`
  height: auto;
  padding: 30px 40px;
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 500px) {
    scrollbar-width: none;
    overflow-y: auto !important;
  }
`

export const Column = styled.div<{ right?: boolean }>`
  width: ${({ right }) =>
    right ? 'calc(54% - 15px)' : 'calc(46% - 15px)'}; /*'calc(61.8% - 15px)' : 'calc(38.2% - 15px)'*/
  display: inline-block;
  vertical-align: top;
  ${({ right }) => (right ? 'margin-top: 4px;' : '')}
  margin-left: ${({ right }) => (right ? 30 : 0)}px;

  @media screen and (max-width: 919px) {
    margin-top: 10px;
    width: 100%;
    margin-left: 0;
  }
`

export const Description = styled.div<{ visible: boolean }>`
  font-size: 11px;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-top: ${({ visible }) => (visible ? '10px' : '0px')};

  :hover {
    opacity: 1 !important;
    /*cursor: pointer;*/
  }

  @media screen and (max-width: 919px) {
    height: ${({ visible }) => (visible ? 'auto' : '0px')};
  }
`

const ExpandChevronIcon = styled.div<{ expanded?: boolean }>`
  opacity: 0.5;
  ${({ expanded }) => (expanded ? 'transform: rotateX(180deg);' : '')}
  transform-origin: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;

  :hover {
    opacity: 1 !important;
    cursor: pointer;
  }

  svg {
    font-size: 24px;
    margin-bottom: ${({ expanded }) => (expanded ? '-8px' : '-6px')};
  }
`

const DescriptionSelector = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  display: none;
  cursor: pointer;
  float: right;
  height: 20px;
  line-height: 66px;
  @media screen and (max-width: 919px) {
    display: inline-block;
  }
`

const HeaderWrapper = styled.div`
  font-size: 32px;
  font-weight: 100;
  width: 100%;
  height: fit-content;
  margin-bottom: 2px;
`

const StrategySubtitle = styled.div`
  margin-top: -7px;
  height: 18px;
`

const OraclesInfo = styled.div`
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  width: fit-content;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 2px;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;

  svg {
    transform: translate(-3px, 2px);
  }
`

const OracleWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};

  img {
    height: 14px;
    width: auto;
    margin-left: 4px;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 5px;
`

const WarningTitle = styled(Title)`
  color: ${({ theme }) => theme.yellow2};
`

const LeverageTag = styled.div`
  display: inline-block;
  background-color: ${({ theme, color }) => (color ? color : theme.bg6)};
  color: ${({ theme }) => theme.text1};
  padding: 2px 7px 4px;
  border-radius: 4px;
  font-weight: 600;
  user-select: none;
  vertical-align: top;
  font-size: 12px;
  margin: 11px 0 4px 7px;
`

const YieldSelector = styled.div`
  margin-top: 2px;
  display: inline-block;
  float: right;
`

export const Subtitle = styled.div`
  font-size: 16px;
  margin: 15px 0 3px;
  font-weight: 600;

  svg {
    transform: translateY(1px);
  }
`

const SubText = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  display: inline-block;
  width: calc(100% - 164px);
  vertical-align: middle;
`

const ChainLogo = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 16px;

  img {
    width: 12px;
  }
`

const ChainLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 3px;
  margin-left: 4px;
  vertical-align: top;
`

const DepositButton = styled.div`
  width: 60px;
  height: 26px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg4};
  text-align: center;
  line-height: 26px;
  cursor: pointer;
  pointer-events: all;
  display: inline-block;
  margin-left: 8px;

  :hover {
    background-color: ${({ theme }) => theme.bg5};
  }

  > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 7px;
  }
`

const DepositButtons = styled.div<{ visible: boolean }>`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.text3};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: ${({ visible }) => (visible ? -6 : -40)}px 0 10px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  height: fit-content;
  padding: 9px 8px 6px;

  > svg {
    color: ${({ theme }) => theme.text3};
    vertical-align: top;
    margin-top: 2px;
    margin-left: 0;
    margin-right: 4px;
  }
`

const BalanceRow = styled.div`
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-weight: 500;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const BalanceWrapper = styled.div`
  display: block;
  cursor: auto;
  text-align: left;
`

const MaxButton = styled.div`
  align-self: flex-end;
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  vertical-align: bottom;

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const NewAmountWrapper = styled.div`
  display: inline-block;

  @media screen and (max-width: 600px) {
    > span > div {
      font-size: 12px !important;
    }
  }
`

const Balance = styled.span`
  font-weight: 200;
`

const Ticker = styled.span`
  font-weight: 200;
  margin-left: 3px;
`

const BalanceAmount = styled.div`
  cursor: pointer !important;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  font-size: 14px;
`

const NewPosition = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 5px 15px 10px;
  margin-bottom: 8px;
  position: relative;

  > div {
    height: auto;
    margin-bottom: 5px;
    margin-top: 0;

    :nth-of-type(1) {
      margin-bottom: 0;
      margin-top: 0px;
    }
  }
`

const UpdatedYieldExplainer = styled.div`
  width: 36%;
  font-size: 9px;
  color: ${({ theme }) => theme.text2};
  text-align: right;
  position: absolute;
  top: 10px;
  right: 15px;

  svg {
    font-size: 10px !important;
    margin-bottom: -2px !important;
    margin-left: -1px !important;
  }
`

const InlineSpinnerWrapper = styled.div`
  display: inline-block;
  width: 132px;
  text-align: center;
  color: ${({ theme }) => theme.text1};

  > div {
    height: 28px !important;
    width: 28px !important;
    margin-bottom: 3px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const NewPositionInfo = styled.div`
  margin-top: 10px;
`

export const TokenValue = styled.div<{ right?: boolean; loading?: boolean }>`
  float: ${({ right }) => (right ? 'left' : 'right')};
  ${({ loading }) => (loading ? 'opacity: 0.7;' : '')};
`

const YieldBreakdownWrapper = styled.div<{ noBackground: boolean }>`
  width: calc(100% + 30px);
  display: inline-block;
  background-color: ${({ theme, noBackground }) => (noBackground ? 'transparent' : theme.bg2)};
  padding: ${({ noBackground }) => (noBackground ? 0 : 10)}px 15px 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: ${({ noBackground }) => (noBackground ? 0 : 8)}px;
  border-radius: 5px;
`

const EarningSection = styled.div<{ right?: boolean; rightModal?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  margin-top: 0;
  margin-left: ${({ right, rightModal }) => (rightModal || right ? 10 : 0)}px;
  text-align: ${({ right, rightModal }) => (rightModal || right ? 'right' : 'left')};
`

const EarningTitle = styled.div`
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 2px;
  margin-top: 8px;
  width: 100%;
`

const OweSection = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
`

const OweTitle = styled(EarningTitle)``

const OweContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
`

const OweUnit = styled.div``

const OweRate = styled.div``

const ScaledDisclaimer = styled.div`
  width: 100%;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  margin-top: 8px;
  margin-bottom: -3px;
`

const UpdatedRatesDisclaimer = styled.div`
  width: 100%;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.blue2};
  margin-top: 3px;
  margin-bottom: -3px;

  svg {
    font-size: 12px !important;
    margin-left: -1px !important;
    margin-bottom: -3px !important;
  }
`

const EarningContent = styled.div`
  width: 100%;

  > div {
    padding: 0;
    font-size: 11px;
  }
`

const Large = styled.span`
  font-size: 1.3em;
  line-height: 1em;
`

const Steps = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const CloseSteps = styled.div`
  width: 100%;
  margin: 20px 0;
`

const StrategyStep = styled.div<{ expanded: boolean }>`
  display: block;
  height: ${({ expanded }) => (expanded ? 64 : 32)}px;
  transition: height 0.3s ease-in-out;
`

export const CloseStrategyStep = styled(StrategyStep)`
  display: block;
  height: ${({ expanded }) => (expanded ? 64 : 32)}px;
  transition: height 0.3s ease-in-out;
`

export const StepSubtitle = styled.div<{ visible: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-top: 4px;
  padding-right: 8%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

export const StrategyProgress = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 100%;
`

/*const Line = styled.div<{ complete: boolean }>`
 height: 1px;
 vertical-align: top;
 background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
 transition: background-color 0.3s ease-in-out;
 display: inline-block;
 margin-top: 7px;
 width: calc(100% - 16px);
 `*/

export const StrategyText = styled.div`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 10px;
  font-size: 16px;
`

const AvgYieldTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -4px;
  margin-top: 8px;
`

const AdvancedDescriptionWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
`

const ErrorMessage = styled(AdvancedDescriptionWrapper)`
  margin-bottom: -15px;
`

const GmFeeExplainerWrapper = styled.div`
  > div {
    position: relative !important;
    top: 0 !important;
    width: 100% !important;
    margin: 5px 0 -5px !important;
    left: 0 !important;
  }
`

const RiskWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 3px;
`

const RiskTitle = styled.div`
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const RiskIconWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.text3};

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    left: 0;
  }
`

const YieldChartWrapper = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  position: relative;
  margin: 30px 0 10px;

  :nth-child(2) {
    position: absolute;
    z-index: 2;
  }
`

const YieldChartTitle = styled.div<{ balances: boolean }>`
  font-size: ${({ balances }) => (balances ? '14px' : '11px')};
  font-weight: ${({ balances }) => (balances ? 300 : 700)};
  color: ${({ theme, balances }) => (balances ? theme.text1 : theme.text3)};
  position: absolute;
  top: -28px;
`

const ChartMinMaxNum = styled.div<{ hide: boolean; position: number }>`
  font-size: 9px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  left: calc(${({ position }) => position * 0.7}% - 9px);
`

const LoadingText = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  line-height: 50px;
`

const ChartMax = styled(ChartMinMaxNum)`
  top: -10px;
`
const ChartMin = styled(ChartMinMaxNum)`
  bottom: -10px;
`

const AverageYield = styled.div<{ hide: boolean; position: number; balances: boolean }>`
  font-size: 13px;
  color: ${({ theme }) => theme.green1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  top: calc(${({ position }) => position}% - 14px);
  left: ${({ balances }) => (balances ? 'calc(80% + 4px)' : 'calc(70% + 4px)')};

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const TooltipDate = styled.div<{ hide: boolean; position: number }>`
  position: absolute;
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  width: 40px;
  text-align: center;
  left: calc(${({ position }) => Math.max(position * 0.7, 5.5)}% - 18px);
  top: -12px;
`

const TooltipYield = styled.div<{ hide: boolean; position: number }>`
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.green1};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  width: 80px;
  text-align: center;
  left: calc(${({ position }) => Math.max(position * 0.7, 10)}% - 35px);
  bottom: -13px;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const HoveredYield = styled.div<{ hide: boolean; position: number }>`
  font-size: 13px;
  color: ${({ theme }) => theme.green1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  top: calc(${({ position }) => position}% - 10px);
  left: 70%;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const AverageYieldTitle = styled.div`
  font-size: 9px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -3px;
`

const NegativeRecolor = styled.div`
  position: absolute;
  top: 30px;
  height: 60px;
  width: 100%;
  left: 0;
  backdrop-filter: brightness(0.5);
  z-index: 4;
`

const NegativeUndoRecolor = styled.div`
  position: absolute;
  top: 30px;
  height: 60px;
  width: 100%;
  left: 0;
  backdrop-filter: brightness(2);
  z-index: 0;
`

const ProjectedProfitWrapper = styled.div`
  width: 100%;
  margin: 10px 0 -2px;
`

const ProjectedProfitDescription = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: -5px;
`

const BasedOnWrapper = styled.div`
  width: 60%;
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  font-weight: 500;
  margin-left: auto;
  margin-bottom: -5px;
  margin-top: 8px;
`

const BasedOnText = styled.div`
  background: ${({ theme }) => theme.bg1};
  width: fit-content;
  padding: 2px 6px;
  margin: 0 auto;
`

const BasedOnLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.text3};
  margin-bottom: -11px;
`

const ProjectedProfitHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  font-weight: 600;
`

const ProjectProfitTable = styled.div`
  width: 100%;
  margin-top: 5px;
  color: ${({ theme }) => theme.text1};
  font-size: 13px;

  span {
    color: ${({ theme }) => theme.text2};
    font-size: 11px;
  }
`

const HealthHeader = styled.div`
  font-size: 12px !important;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  vertical-align: top;
  margin-top: 3px;
  display: inline-block;
  margin-left: 5px;
`

const ProjectedProfitRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ProjectedProfitPeriod = styled.div`
  width: 20%;
  text-align: left;
`

const ProjectedProfitCurrent = styled.div`
  width: 40%;
  text-align: right;
`

const ProjectedProfitAverage = styled.div`
  width: 40%;
  text-align: right;
`

export const Circle = styled.div<{ complete: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : 'none')};
  font-size: 14px;
  font-weight: 400;
    // padding-left: ${({ complete }) => (complete ? 1 : 8.5)}px;
    // padding-top: ${({ complete }) => (complete ? 1.4 : 2)}px;
  padding-left: ${({ complete }) => (complete ? -1 : 6)}px;
  padding-top: ${({ complete }) => (complete ? 1.4 : -2)}px;

  svg {
    height: 18px !important;
    margin-left: -2px !important;
  }
`

export const ProgressLine = styled.div<{ complete: boolean }>`
  width: 2px;
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  transition: background-color 0.3s ease-in-out;
  margin-left: 11px;
  height: calc(100% - 22px);
`

const OracleTooltipInner = styled.div`
  width: 280px;
  padding: 8px 12px;
  color: ${({ theme }) => theme.text1};
  font-size: 11px;
  font-weight: 500;

  > div:nth-of-type(1) {
    margin-bottom: 8px;
  }
`

const OracleTooltipSection = styled.div`
  > div {
    display: inline-block;
    text-align: left;
  }
`

const OracleTooltipAsset = styled.div`
  font-weight: 600;
  width: 25%;
  vertical-align: top;
`

const OracleTooltipPath = styled.div`
  color: ${({ theme }) => theme.text2};
  text-align: left;
  width: 75%;
`

const PathSection = styled.span`
  white-space: nowrap;
`

const OracleTooltip = ({ strategy }: { strategy: StrategyInfo }) => {
  return (
    <OracleTooltipInner>
      <OracleTooltipSection>
        <OracleTooltipAsset>{cleanCurrencySymbol(strategy.collateralAssets[0])}:</OracleTooltipAsset>
        <OracleTooltipPath>
          {strategy.supplyOraclePath.map((pathSection, i) => {
            if (i === 0) return pathSection
            return (
              <span key={i}>
                {` > `}
                <PathSection key={i}>{pathSection}</PathSection>
              </span>
            )
          })}
        </OracleTooltipPath>
      </OracleTooltipSection>
      <OracleTooltipSection>
        <OracleTooltipAsset>{cleanCurrencySymbol(strategy.debtAssets[0])}:</OracleTooltipAsset>
        <OracleTooltipPath>
          {strategy.borrowOraclePath.map((pathSection, i) => {
            if (i === 0) return pathSection
            return (
              <span key={i}>
                {` > `}
                <PathSection key={i}>{pathSection}</PathSection>
              </span>
            )
          })}
        </OracleTooltipPath>
      </OracleTooltipSection>
    </OracleTooltipInner>
  )
}

export function Header({
  strategy,
  expirationDate,
  showExpirationWarning,
  partsFormatted,
  totalYield,
  health,
}: {
  strategy: StrategyInfo
  expirationDate: string | undefined
  showExpirationWarning: boolean
  partsFormatted: string[]
  totalYield: boolean
  health?: Fraction
}) {
  const { t } = useTranslation()
  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(health)
  }, [health])
  return (
    <HeaderWrapper>
      {strategy.title}
      <LeverageTag color={Tags.leverage2x.color}>{strategy.leverage ?? 1}x</LeverageTag>
      <StrategySubtitle>
        <ChainLogo>
          <img alt={`${NETWORK_LABELS[strategy.chain]} logo`} src={NETWORK_LOGOS[strategy.chain]} />
        </ChainLogo>
        <ChainLabel>{NETWORK_LABELS[strategy.chain]}</ChainLabel>
        <StyledTooltip title={strategy.riskAssessment} placement={'top'}>
          <RiskWrapper>
            <RiskTitle>Risk: </RiskTitle>
            <RiskIconWrapper>
              <SignalCellularAltIcon />
              <StrategyRiskIcon risk={strategy.risk}>
                {strategy.risk === Risk.low ? (
                  <SignalCellularAlt1BarIcon />
                ) : strategy.risk === Risk.medium ? (
                  <SignalCellularAlt2BarIcon />
                ) : (
                  <SignalCellularAltIcon />
                )}
              </StrategyRiskIcon>
            </RiskIconWrapper>
          </RiskWrapper>
        </StyledTooltip>
        {health && (
          <HealthHeader>
            Health:{' '}
            <StrategyHealth health={positionHealthFactor}>
              <StyledTooltip
                title={
                  <PositionHealthTooltip
                    positionHealth={parseFloat(health.toFixed(2, undefined, Rounding.ROUND_DOWN))}
                    positionHealthFactor={positionHealthFactor}
                    t={t}
                    isNew={false}
                  />
                }
                placement='top'
                arrow={true}
              >
                <span>
                  {!health ? (
                    <InfinityWrapper expanded={true}>
                      <AllInclusiveIcon />
                    </InfinityWrapper>
                  ) : health.greaterThan('10') ? (
                    '>10'
                  ) : (
                    health.toFixed(2, undefined, Rounding.ROUND_DOWN)
                  )}
                </span>
              </StyledTooltip>
            </StrategyHealth>
          </HealthHeader>
        )}
        {expirationDate && (
          <Expiration closeToExpiry={showExpirationWarning}>
            Expires: {expirationDate}
            {showExpirationWarning && <WarningRoundedIcon />}
          </Expiration>
        )}
        <StyledTooltip title={<OracleTooltip strategy={strategy} />} placement={'top'}>
          <OraclesInfo>
            Oracles <StyledTooltipWithIcon tooltipText={undefined} />
          </OraclesInfo>
        </StyledTooltip>
      </StrategySubtitle>
      <OracleWrapper>
        {/*/!*strategy.supplyOracleType === OracleType.other ? (*/}
        {/*    <>Other</>*/}
        {/*  ) : (*/}
        {/*    <img*/}
        {/*      alt={'oracle logo'}*/}
        {/*      src={strategy.supplyOracleType === OracleType.chainlink ? ChainlinkLogo : ChronicleLogo}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</StyledTooltip>*/}
        {/*/*/}
        {/*<StyledTooltip title={<OracleTooltip strategy={strategy} />} placement={'top'}>*/}
        {/*  {strategy.borrowOracleType === OracleType.other ? (*/}
        {/*    <>Other</>*/}
        {/*  ) : (*/}
        {/*    <img*/}
        {/*      alt={'oracle logo'}*/}
        {/*      src={strategy.borrowOracleType === OracleType.chainlink ? ChainlinkLogo : ChronicleLogo}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</StyledTooltip>*!/*/}
      </OracleWrapper>
      <TagsWrapper>
        {strategy.tags.map((tag: StrategyTag, i: number) => {
          const tagText = tag.name
          const matchingTag = partsFormatted.find(part => part.includes(tagText))
          const color = tag.color
          const inactive = !totalYield && color === '#2b6dc2' && !strategy.includeRates?.includes(tagText)
          if (['2x', '3x', '4x', '5x'].indexOf(tag.name) > -1) return
          return (
            <Tag key={i} color={inactive ? '#565A69' : color}>
              {matchingTag ?? tagText}
            </Tag>
          )
        })}
      </TagsWrapper>
    </HeaderWrapper>
  )
}

interface InterestRateChartRecord {
  supplyInterestRate: Fraction
  borrowInterestRate: Fraction
  netYield: Fraction
  pendleYield?: Fraction
  timestamp: Date
  dateString: string
  averageYield: Fraction | undefined
}

export function YieldBreakdown({
  collateralInterestRateParts,
  collateralLendingYield,
  showYieldAsApr,
  collateralTotalYield,
  leverage,
  totalYield,
  strategy,
  debtToken,
  debtInterestRate,
  breakdownDisabled,
  newSupplyRate,
  newBorrowRate,
  pendleFixedRate,
  token,
  height,
  chartType,
}: {
  collateralInterestRateParts: InterestRatePart[] | undefined
  collateralLendingYield: Percent | undefined
  showYieldAsApr: boolean
  collateralTotalYield: Percent | undefined
  leverage: number
  totalYield: boolean
  strategy: StrategyInfo | undefined
  debtToken: Token | undefined
  debtInterestRate: Percent | undefined
  breakdownDisabled?: boolean
  newSupplyRate?: Percent
  newBorrowRate?: Percent
  pendleFixedRate?: Fraction
  token?: Token
  height?: number
  chartType?: ChartTypes
}) {
  const { chainId } = useActiveWeb3React()
  const [hoveredDate, setHoveredDate] = useState<string | undefined>(undefined)
  const [hoveredYield, setHoveredYield] = useState<number | undefined>(undefined)
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined)
  const collateralInterestRateHistory = useHistoricalInterestRateSeries(
    strategy ? strategy.collateralAssets[0] : token,
    strategy ? strategy.chain : chainId,
  )
  const debtInterestRateHistory = useHistoricalInterestRateSeries(
    strategy ? strategy.debtAssets[0] : token,
    strategy ? strategy.chain : chainId,
  )
  const isPendleInterestRateEnabled = true
  const pendleInterestRate = useMemo(
    () =>
      pendleFixedRate
        ? Percent.fromFraction(pendleFixedRate.asFraction)
        : isPendleInterestRateEnabled && collateralInterestRateParts && collateralInterestRateParts.length > 0
        ? collateralInterestRateParts.find(part => part.label.includes('Pendle Fixed'))?.interestRate ?? undefined
        : undefined,
    [collateralInterestRateParts, isPendleInterestRateEnabled, pendleFixedRate],
  )

  const combinedRateData = useMemo(() => {
    const combinedHistory: InterestRateChartRecord[] = []
    if (!collateralInterestRateHistory || !debtInterestRateHistory) return undefined

    // Calculate the shorter length
    const minLength = Math.min(collateralInterestRateHistory.length, debtInterestRateHistory.length)

    // Loop backwards, since the most recent timestamps will always match
    for (let i = 1; i <= minLength; i++) {
      const collateralEntry = collateralInterestRateHistory[collateralInterestRateHistory.length - i]
      const debtEntry = debtInterestRateHistory[debtInterestRateHistory.length - i]
      const netYield =
        !chartType || (chartType as ChartTypes) === ChartTypes.Supply
          ? collateralEntry.supplyInterestRate
              .multiply(leverage)
              .subtract(debtEntry.borrowInterestRate?.multiply(leverage - 1) ?? ZERO_FRACTION)
          : debtEntry.borrowInterestRate ?? ZERO_FRACTION
      // Assuming the timestamps match since we're starting from the end
      if (collateralEntry.timestamp.getTime() === debtEntry.timestamp.getTime()) {
        combinedHistory.unshift({
          supplyInterestRate: collateralEntry.supplyInterestRate,
          borrowInterestRate: !strategy ? ZERO_FRACTION : debtEntry.borrowInterestRate ?? ZERO_FRACTION,
          netYield: netYield,
          pendleYield: pendleInterestRate?.asFraction
            .multiply(100)
            .multiply(leverage)
            .subtract(debtEntry.borrowInterestRate?.multiply(leverage - 1) ?? ZERO_FRACTION),
          timestamp: collateralEntry.timestamp,
          dateString: dayjs(collateralEntry.timestamp).format('MMM D'),
          averageYield: undefined,
        })
      }
    }

    return combinedHistory
  }, [
    collateralInterestRateHistory,
    debtInterestRateHistory,
    strategy,
    leverage,
    pendleInterestRate?.asFraction,
    chartType,
  ])

  const chartMinMax = useMemo(() => {
    return combinedRateData?.reduce(
      (acc, item, index) => {
        if (
          !acc.highest.value ||
          item.netYield.greaterThan(acc.highest.value) ||
          item.pendleYield?.greaterThan(acc.highest.value)
        ) {
          acc.highest.value =
            item.pendleYield && item.pendleYield.greaterThan(item.netYield) ? item.pendleYield : item.netYield
          acc.highest.index = index
          acc.highest.name = item.dateString
        }
        if (
          !acc.lowest.value ||
          item.netYield.lessThan(acc.lowest.value) ||
          item.pendleYield?.lessThan(acc.lowest.value)
        ) {
          acc.lowest.value =
            item.pendleYield && item.pendleYield.lessThan(item.netYield) ? item.pendleYield : item.netYield
          acc.lowest.index = index
          acc.lowest.name = item.dateString
        }
        acc.totalCy = acc.totalCy.add(item.netYield)

        return acc
      },
      {
        highest: {
          value: undefined as Fraction | undefined,
          index: -1,
          name: '',
        },
        lowest: {
          value: undefined as Fraction | undefined,
          index: -1,
          name: '',
        },
        totalCy: ZERO_FRACTION,
      },
    )
  }, [combinedRateData])
  const averageYield = useMemo(() => {
    return combinedRateData ? chartMinMax?.totalCy.divide(Math.max(combinedRateData.length, 1)) : undefined
  }, [chartMinMax?.totalCy, combinedRateData])
  const populatedChartData = useMemo(
    () =>
      combinedRateData?.map(item => ({
        name: item.dateString,
        netYield: parseFloat(item.netYield.toFixed(2)),
        pendleYield: item.pendleYield ? parseFloat(item.pendleYield.toFixed(2)) : undefined,
        averageYield: averageYield ? parseFloat(averageYield.toFixed(2)) : undefined,
      })),
    [averageYield, combinedRateData],
  )
  const chartLoaded =
    populatedChartData !== undefined &&
    populatedChartData.length > 0 &&
    combinedRateData !== undefined &&
    combinedRateData.length > 0 &&
    chartMinMax !== undefined &&
    chartMinMax.highest &&
    chartMinMax.highest.value !== undefined &&
    chartMinMax.lowest &&
    chartMinMax.lowest.value !== undefined &&
    averageYield
  const heightValue = chartMinMax?.highest?.value?.subtract(chartMinMax?.lowest?.value ?? ZERO_PERCENT) ?? ZERO_FRACTION
  const rateTitle = (chartType as ChartTypes) === ChartTypes.Supply ? ' Supply' : ' Borrow'
  return (
    <YieldBreakdownWrapper noBackground={!!breakdownDisabled || !strategy}>
      <YieldChartWrapper height={height ?? 50}>
        <YieldChartTitle balances={!strategy}>
          Historical{/*!strategy && rateTitle*/} APR {chartLoaded ? `(${populatedChartData.length} Days)` : ''}
        </YieldChartTitle>
        {chartLoaded ? (
          <ResponsiveContainer width={!strategy ? '80%' : '70%'} height='100%'>
            <LineChart
              width={250}
              height={60}
              data={populatedChartData}
              onMouseMove={(chart: any) => {
                if (!chart.activePayload || chart.activePayload.length === 0) {
                  return
                }

                const payload = chart.activePayload[0].payload
                if (populatedChartData && payload) {
                  setHoveredIndex(
                    populatedChartData.findIndex(
                      item =>
                        item.name === payload.name &&
                        item.netYield === payload.netYield &&
                        item.averageYield === payload.averageYield,
                    ),
                  )
                }
                if (setHoveredYield && populatedChartData.length) {
                  setHoveredYield(payload.netYield)
                }
                if (setHoveredDate) {
                  setHoveredDate(payload.name)
                }
                /*const formattedTime = dayjs(payload.time).format('MMM D, YYYY')
                 if (setLabel && label !== formattedTime && chartData.length && !customAxis) {
                 setLabel(formattedTime)
                 }*/
              }}
              onMouseLeave={() => {
                setHoveredYield && populatedChartData.length && setHoveredYield(undefined)
                setHoveredDate && populatedChartData.length && setHoveredDate(undefined)
              }}
            >
              <YAxis
                hide
                type='number'
                domain={['dataMin - (0.2 * (dataMax - dataMin))', 'dataMax + (0.2 * (dataMax - dataMin))']}
              />
              <Tooltip wrapperStyle={{ display: 'none' }} />
              <Line
                type={'monotone'}
                dataKey={'netYield'}
                stroke={'#d5d6e1'}
                strokeWidth={1.5}
                activeDot={{
                  stroke: '#f9f9f9',
                  strokeWidth: 2,
                  r: 2,
                }}
                dot={false}
              />
              <Line
                type={'monotone'}
                dataKey={'averageYield'}
                stroke={'#8fc942'}
                strokeWidth={1}
                strokeDasharray={'5, 6'}
                activeDot={false}
                dot={false}
              />
              {pendleInterestRate && (
                <Line
                  type={'monotone'}
                  dataKey={'pendleYield'}
                  stroke={'#50faec'}
                  strokeWidth={1}
                  activeDot={false}
                  dot={false}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <LoadingText>Loading...</LoadingText>
        )}
        {/*hoveredDate && <TooltipDate >{hoveredDate}</TooltipDate>*/}
        {chartLoaded && (
          <ChartMax
            hide={hoveredYield !== undefined}
            position={(chartMinMax.highest.index / populatedChartData.length) * 100}
          >
            {chartMinMax.highest.value?.toFixed(2)}%
          </ChartMax>
        )}
        {chartLoaded && (
          <ChartMin
            hide={hoveredYield !== undefined}
            position={(chartMinMax.lowest.index / populatedChartData.length) * 100}
          >
            {chartMinMax.lowest.value?.toFixed(2)}%
          </ChartMin>
        )}
        {chartLoaded && chartMinMax.highest.value !== undefined && chartMinMax.lowest.value !== undefined && (
          <AverageYield
            hide={false /*hoveredYield !== undefined*/}
            position={parseFloat(
              chartMinMax.highest.value
                .subtract(averageYield)
                .divide(heightValue.greaterThan(ZERO_FRACTION) ? heightValue : ONE_FRACTION)
                .multiply(100)
                .toFixed(2),
            )}
            balances={!strategy}
          >
            <AverageYieldTitle>{populatedChartData.length} Day Avg.</AverageYieldTitle>
            {averageYield.toFixed(2)}% <span>APR{/*showYieldAsApr ? `APR` : `APY`*/}</span>
          </AverageYield>
        )}
        {chartLoaded && hoveredYield !== undefined && hoveredIndex !== undefined && hoveredDate !== undefined && (
          <TooltipDate hide={hoveredDate === undefined} position={(hoveredIndex / populatedChartData.length) * 100}>
            {hoveredDate}
          </TooltipDate>
        )}
        {chartLoaded && hoveredYield !== undefined && hoveredIndex !== undefined && hoveredDate !== undefined && (
          <TooltipYield hide={hoveredYield === undefined} position={(hoveredIndex / populatedChartData.length) * 100}>
            {hoveredYield.toFixed(2)}% <span>{showYieldAsApr ? `APR` : `APY`}</span>
          </TooltipYield>
        )}
        {/*<NegativeRecolor />
         <NegativeUndoRecolor />*/}
        {/*<AverageYield hide={!hoveredYield || !hoveredDate} position={40}>
         <AverageYieldTitle>{hoveredDate}</AverageYieldTitle>
         {hoveredYield}% <span>{showYieldAsApr ? `APR` : `APY`}</span>
         </AverageYield>*/}
        {/*<NegativeRecolor />
         <NegativeUndoRecolor />*/}
      </YieldChartWrapper>
      {!breakdownDisabled && (
        <>
          {collateralInterestRateParts && (
            <EarningSection>
              <EarningTitle>You Earn:</EarningTitle>
              <EarningContent>
                <YieldTooltip
                  interestRateParts={collateralInterestRateParts}
                  isBorrowRate={false}
                  lendingYield={collateralLendingYield}
                  showYieldAsApr={showYieldAsApr}
                  totalSupplyYield={collateralTotalYield}
                  leverage={leverage}
                  includeRates={!totalYield}
                  rateParts={strategy?.includeRates}
                  newRate={newSupplyRate}
                  pendleFixedRate={pendleFixedRate}
                />
              </EarningContent>
            </EarningSection>
          )}
          {debtToken && (
            <OweSection>
              <OweTitle>You Owe:</OweTitle>
              <OweContent>
                <OweUnit>
                  {cleanCurrencySymbol(debtToken)} Borrow {showYieldAsApr ? 'APR' : 'APY'}:
                </OweUnit>
                <StyledTooltip
                  title={
                    leverage
                      ? `Base rate of ${(newBorrowRate ?? debtInterestRate)?.toFixed(2) ??
                          '-'}% with a leverage of ${leverage - 1}`
                      : undefined
                  }
                  placement={'top'}
                >
                  <OweRate>{(newBorrowRate ?? debtInterestRate)?.multiply(leverage - 1).toFixed(2) ?? '-'}%</OweRate>
                </StyledTooltip>
              </OweContent>
            </OweSection>
          )}
          <ScaledDisclaimer>Rates scaled to strategy leverage</ScaledDisclaimer>
          {newBorrowRate && newSupplyRate && (
            <UpdatedRatesDisclaimer>
              Rates updated to reflect your deposit size{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'Utilizing a strategy results in more assets being borrowed and supplied, which changes the rates to borrow and supply assets. The rates displayed reflect the change that will result from your deposit size.'
                }
              />
            </UpdatedRatesDisclaimer>
          )}
        </>
      )}
    </YieldBreakdownWrapper>
  )
}

export function StrategyDescription({
  showDescription,
  strategy,
}: {
  showDescription: boolean
  strategy: StrategyInfo
}) {
  return (
    <Description visible={showDescription}>
      {strategy.description}
      <Subtitle>Why the Strategy Works</Subtitle>
      {strategy.whyItWorks}
      <Subtitle>
        Risks
        <StyledTooltipWithIcon
          tooltipText={
            'These are some of the risks associated with this strategy, but do not represent all possible risks. Please research assets and strategies to make sure you understand the potential risks.'
          }
        />
      </Subtitle>
      {strategy.risks}
    </Description>
  )
}

const StrategyRiskIcon = styled.div<{ risk: Risk }>`
  color: ${({ theme, risk }) => (risk === Risk.low ? theme.green1 : risk === Risk.medium ? theme.yellow1 : theme.red1)};
`

const RANDOM_NUMBER = Math.floor(Math.random() * 10000000000) // used on a "per session" basis

interface MultiStepInfo {
  step: StrategyStage
  position: BorrowPosition
}

interface CreatePositionModalProps {
  strategy: StrategyInfo
  isOpen: boolean
  closeModal: () => void
  interestRatePair: (Percent | undefined)[]
  totalYieldSelected: boolean
  addOpeningPosition: (positionId: string) => void
  transactionLoading: (positionId?: string) => void
  stepsData?: MultiStepInfo
}

function CreatePositionModal({
  strategy,
  isOpen,
  closeModal,
  interestRatePair,
  totalYieldSelected,
  addOpeningPosition,
  transactionLoading,
  stepsData,
}: CreatePositionModalProps) {
  const { account, chainId, setChain } = useActiveWeb3React()
  const { t } = useTranslation()
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [loadingChain, setLoadingChain] = useState<ChainId | undefined>(undefined)
  const [totalYield, setTotalYield] = useState(totalYieldSelected)
  const [loadingZap, setLoadingZap] = useState<boolean>(false)
  const collateralToken = strategy.collateralAssets[0]
  const collateral = cleanCurrencySymbol(collateralToken)
  const debtToken = strategy.debtAssets[0]
  const debt = cleanCurrencySymbol(strategy.debtAssets[0])
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [zapOutputValue, setZapOutputValue] = useState<CurrencyAmount<Token> | undefined>(undefined)
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [dolomiteBalanceData, isLoading] = useDolomiteBalancesWithLoadingIndicator(account, strategy.collateralAssets)
  const [strategyStage, setStrategyStage] = useState<StrategyStage>(stepsData ? stepsData.step : StrategyStage.OPEN)
  const [isDepositModalOpen, setDepositModalOpen] = useState<boolean>(false)
  const [isSwapModalOpen, setSwapModalOpen] = useState<boolean>(false)
  const [showDescription, setShowDescription] = useState<boolean>(true)
  const [isMaxSelected, setIsMaxSelected] = useState<boolean>(false)
  const isGeoRestrictedCollateral = useIsTokenGeoRestricted(collateralToken.wrapped.address)
  const isGeoRestrictedDebt = useIsTokenGeoRestricted(debtToken.wrapped.address)
  const isGeoRestricted = isGeoRestrictedCollateral || isGeoRestrictedDebt
  const selectedSpecialAsset = useSpecialAsset(collateralToken)
  const [inProgressOpenId, setInProgressOpenId] = useState<JSBI | undefined>(undefined)
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined)
  const isolationModeSpecialAsset = selectedSpecialAsset?.isIsolationMode ? selectedSpecialAsset : undefined
  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(zapRefreshIncrementor + 1)
  }, [zapRefreshIncrementor])

  const leverage = strategy.leverage ?? 1
  const steps = strategy.steps ?? 1
  const tokenList = useMemo(() => (collateralToken && debtToken ? [collateralToken, debtToken] : undefined), [
    collateralToken,
    debtToken,
  ])
  const [fiatValueMap, loading] = useFiatPricesWithLoadingIndicator(tokenList, strategy.chain, RefreshFrequency.Medium)
  const [zapBorrowAmount, setZapBorrowAmount] = useState<CurrencyAmount<Token> | undefined>(undefined)
  const { data: interestRateMap } = useInterestRateData(strategy.chain)
  const [supplyAmounts, borrowAmounts] = useStrategyAmounts(strategy)
  const interestRate = interestRatePair[totalYield ? 0 : 1]
  const historicalInterestRate = useMemo(
    () => getStrategyInterestRate(supplyAmounts, borrowAmounts, fiatValueMap, interestRateMap, totalYieldSelected),
    [borrowAmounts, fiatValueMap, interestRateMap, supplyAmounts, totalYieldSelected],
  )
  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const dolomiteMarginContract = useDolomiteMarginContract()
  const rawProtocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, tokenList)
  const protocolBalanceInfoMap = useAddExternalTokensToDolomiteData(rawProtocolBalanceInfoMap)
  const marketTotalWeiMap = useDolomiteMarginTokenTvlData()
  const { data: riskParams } = useDolomiteMarginData()
  const parsedHistoricalRate =
    parseFloat(historicalInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalInterestRate, 2)
      : '0.00%'
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [transactionPendingText, setTransactionPendingText] = useState('')
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [openingIdMap, setOpeningIdMap] = useState<Record<string, boolean>>({})
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  // const interestRate = useStrategyInterestRate(
  //   strategy.borrowAmounts,
  //   strategy.supplyAmounts,
  //   strategy.chain,
  // )

  const parsedInputValue = useMemo(() => {
    if (stepsData?.position) {
      return tryParseAmount(
        stepsData.position.supplyAmounts[0].amountTokenWei.toFixed(collateralToken.decimals),
        collateralToken,
      )
    }
    return tryParseAmount(inputValue, collateralToken)
  }, [inputValue, collateralToken, stepsData])
  const defaultAccountNumber = useDefaultMarginAccount().accountNumber
  const { data: borrowCountFraction } = useUserLifetimeBorrowPositionCount(account)
  const borrowCount = borrowCountFraction?.toFixed(0)
  const strategyIdPortion = STRATEGY_POSITION_ID_THRESHOLD * parseInt(strategy.id)
  const borrowId = useMemo(
    () => (borrowCount ? JSBI.BigInt(parseInt(borrowCount) + STRATEGY_INDEX_LOWER + strategyIdPortion) : undefined),
    [borrowCount, strategyIdPortion],
  )
  const isTxPending = useIsTransactionPending(txHash)
  //TODO - update to account for multiple assets, right now it's just built for one collateral and one debt
  /*const rawBorrowAmount = useMemo(() => {
   if (
   !parsedInputValue ||
   parsedInputValue.equalTo(ZERO_FRACTION) ||
   !fiatValueMap ||
   !fiatValueMap[parsedInputValue.currency.address]
   )
   return undefined
   return tryParseAmount(
   (parsedInputValue?.asFraction ?? ZERO_FRACTION)
   .multiply(leverage)
   .subtract(parsedInputValue?.asFraction ?? ZERO_FRACTION)
   .multiply(
   (fiatValueMap[parsedInputValue?.currency.address ?? ''] ?? ZERO_FRACTION).divide(
   fiatValueMap[debtToken.address ?? ''] ?? ONE_FRACTION,
   ),
   )
   .toFixed(debtToken.decimals ?? 0),
   debtToken,
   )
   }, [debtToken, fiatValueMap, leverage, parsedInputValue])
   const borrowAmount = useDebounce(rawBorrowAmount, 300)*/

  useEffect(() => {
    if (
      !parsedInputValue ||
      parsedInputValue.equalTo(ZERO_FRACTION) ||
      !fiatValueMap ||
      !fiatValueMap[parsedInputValue.currency.address]
    )
      return undefined
    if (parsedInputValue) {
      const newZapBorrowAmountFraction = parsedInputValue
        .multiply(leverage - 1)
        .multiply(
          (fiatValueMap[parsedInputValue.currency.address] ?? ZERO_FRACTION).divide(
            fiatValueMap[debtToken.address ?? '']?.greaterThan(ZERO_FRACTION)
              ? fiatValueMap[debtToken.address ?? ''] ?? ONE_FRACTION
              : ONE_FRACTION,
          ),
        ).asFraction
      const newZapBorrowAmount = tryParseAmount(newZapBorrowAmountFraction.toFixed(debtToken.decimals ?? 0), debtToken)
      if (
        newZapBorrowAmount &&
        (!zapBorrowAmount ||
          newZapBorrowAmount
            .subtract(zapBorrowAmount)
            .divide(zapBorrowAmount.greaterThan(ZERO_FRACTION) ? zapBorrowAmount : ONE_FRACTION)
            .abs.multiply(1000)
            .greaterThan(5))
      ) {
        setZapBorrowAmount(newZapBorrowAmount)
      }
    }
  }, [debtToken, debtToken.address, fiatValueMap, leverage, parsedInputValue, zapBorrowAmount])
  const borrowAmount = zapBorrowAmount

  const zapPositionId = useMemo(() => {
    if (stepsData) return stepsData.position.marginAccount.accountNumber
    if (inProgressOpenId) return inProgressOpenId
    return borrowId
  }, [stepsData, inProgressOpenId, borrowId])

  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    zapBorrowAmount,
    collateralToken,
    true,
    zapRefreshIncrementor,
    zapPositionId?.toString(),
    fiatValueMap,
  )

  const zapEither = useMemo<ZapEither | undefined>(() => {
    if (!parsedInputValue) return undefined
    if (steps === 1) {
      return {
        zapType: undefined,
        transfers: [
          {
            isToTradeAccount: true,
            eventType: ZapEventType.OpenBorrowPosition,
            amount: parsedInputValue,
            transferOpts: undefined, // TODO - isMaxSelected ? ZapTransferType.ALL_BALANCE : undefined,
          },
        ],
      }
    }
    return {
      zapType: ZapType.SwapDebt,
      transfers: undefined,
    }
  }, [parsedInputValue, steps])
  const summary = useMemo(() => {
    if (steps > 1 && strategyStage === StrategyStage.ZAP) {
      return `Borrow ${debt} and zap into ${collateral} collateral in ${strategy.title} ${leverage}x strategy`
    }
    return `Deposit ${formatAmount(parsedInputValue)} ${collateral} into ${strategy.title} ${leverage}x`
  }, [steps, strategyStage, parsedInputValue, collateral, strategy.title, leverage, debt])
  const { callback: submitZap, error: submitZapError } = useZapExactTokensForTokens(
    zapPositionId,
    defaultAccountNumber,
    zaps,
    zapEither,
    isolationModeSpecialAsset,
    isMaxSelected,
    summary,
  )

  const bestZap = useMemo(() => zaps?.[0], [zaps])
  useEffect(() => {
    if (bestZap) {
      const expectedAmountOut = CurrencyAmount.fromRawAmount(collateralToken, bestZap.expectedAmountOut.toFixed(0))
      setZapOutputValue(expectedAmountOut)
      setLoadingZap(false)
    } else {
      setLoadingZap(true)
    }
  }, [bestZap, collateralToken])

  const handleTypeInput = useCallback((value: string) => {
    setZapOutputValue(undefined)
    setInputValue(value)
    setIsMaxSelected(false)
  }, [])
  const collateralBalance = dolomiteBalanceData[collateralToken.address]
  const changedBalanceValue = useMemo(
    () => (parsedInputValue ? (collateralBalance ?? ZERO_FRACTION).subtract(parsedInputValue) : undefined),
    [collateralBalance, parsedInputValue],
  )

  const setMax = useCallback(() => {
    setInputValue(collateralBalance ? collateralBalance.toFixed(collateralBalance.currency.decimals) : '0')
    // TODO - setIsMaxSelected(true)
  }, [collateralBalance])

  // const { callback: executeZapCallback, error: openMarginPositionError } = useZapExactTokensForTokens(
  //   tradeAccountNumber,
  //   subAccountNumber,
  //   zaps,
  //   specialAsset,
  //   ExtraZapInfo.AddCollateralAndZapInput,
  //   true,
  //   ZapEventType.MarginPosition,
  //   false,
  //   marginDeposit,
  // )

  const { data: totalBorrowPositionCount } = useUserLifetimeBorrowPositionCount(account)
  const nonce = totalBorrowPositionCount?.toFixed(0) ?? '0'

  const positionId = useMemo(() => {
    return JSBI.BigInt(
      ethers.utils.solidityKeccak256(
        ['address', 'uint256', 'uint256'],
        [account ?? ZERO_ADDRESS, nonce, RANDOM_NUMBER],
      ),
    )
  }, [account, nonce])

  const { callback: openPositionCallback, error: openPositionCallbackError } = useOpenBorrowPosition(
    defaultAccountNumber,
    borrowId,
    parsedInputValue,
    BalanceCheckFlag.FromAccount,
  )
  const addCollateralToNewPosition = useCallback(() => {
    if (!openPositionCallback || !parsedInputValue) {
      console.error('No open position callback due to error:', openPositionCallbackError)
      return
    }

    const actionText = `Open borrow position and add collateral`
    setSelectedToken(parsedInputValue.currency)
    setIsAttemptingTx(true)
    setTransactionPendingText(actionText)
    openPositionCallback()
      .then(({ positionId, transactionHash }) => {
        setTxHash(transactionHash)
        setIsAttemptingTx(false)
        setStrategyStage(StrategyStage.ZAP)
        ReactGA.event({
          category: 'Borrow Position',
          action: 'Open borrow position',
          label: [cleanCurrencySymbol(parsedInputValue?.currency)].join('/'),
        })
        addOpeningPosition(positionId)
        setLoadingId(positionId)
        const splitId = positionId.split('-')
        splitId.length > 1 && setInProgressOpenId(JSBI.BigInt(splitId[1]))
      })
      .catch(e => {
        if (e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient funds to pay gas fee to open transaction.')
        } else {
          console.error(e.message)
        }
        setIsAttemptingTx(false)
      })
  }, [
    openPositionCallback,
    openPositionCallbackError,
    setIsAttemptingTx,
    setTxHash,
    addOpeningPosition,
    parsedInputValue,
  ])

  const handleZap = useCallback(async () => {
    if (!submitZap || !parsedInputValue || !zapOutputValue) {
      console.error('Callback is not defined for submitting borrow action:', submitZapError)
      return
    }

    const amount = formatAmount(parsedInputValue)
    const unwrappedSymbol = cleanCurrencySymbol(parsedInputValue.currency)
    const unwrappedZapSymbol = cleanCurrencySymbol(zapOutputValue.currency)
    const zapAmount = formatAmount(zapOutputValue)
    const actionText = `Zap borrowed ${debt} to ~${zapAmount} ${unwrappedZapSymbol} and deposit into borrow position` // TODO - completely incorrect
    setSelectedToken(parsedInputValue.currency)
    setIsAttemptingTx(true)
    setTransactionPendingText(actionText)
    const strategyIdPortion = STRATEGY_POSITION_ID_THRESHOLD * parseInt(strategy.id)
    const strategyId = `${account?.toLowerCase()}-${parseFloat(nonce) + STRATEGY_INDEX_LOWER + strategyIdPortion}`
    submitZap()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
        setStrategyStage(StrategyStage.COMPLETE)
        setInputValue('')
        closeModal()
        transactionLoading(loadingId)
        addOpeningPosition(strategyId ?? '')
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [
    submitZap,
    parsedInputValue,
    zapOutputValue,
    debt,
    submitZapError,
    loadingId,
    closeModal,
    strategy.id,
    addOpeningPosition,
    transactionLoading,
    account,
    nonce,
  ])
  const wrongChain = strategy.chain !== chainId
  const selectNetwork = useCallback(
    async (selectedChainId: ChainId) => {
      setLoadingChain(selectedChainId)
      await setChain(selectedChainId).then(() => {
        setLoadingChain(undefined)
      })
    },
    [setChain],
  )

  const onConfirm = useCallback(() => {
    if (strategy.chain && wrongChain) {
      return selectNetwork(strategy.chain)
    }
    if (strategy.steps) {
      if (strategyStage === StrategyStage.OPEN) return addCollateralToNewPosition()
      else {
        return handleZap()
      }
    }
    return handleZap()
  }, [handleZap, selectNetwork, strategy, wrongChain, strategyStage, addCollateralToNewPosition])

  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])

  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  const collateralInterestRateParts =
    interestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const collateralLendingYield = showYieldAsApr
    ? interestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate
    : calculateAprToApy(interestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate ?? ZERO_PERCENT)
  const collateralTotalYield = showYieldAsApr
    ? interestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate
    : calculateAprToApy(interestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate ?? ZERO_PERCENT)
  const debtInterestRate = showYieldAsApr
    ? interestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate
    : calculateAprToApy(interestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate ?? ZERO_PERCENT)
  const partsFormatted = useMemo(() => {
    return collateralInterestRateParts
      ? collateralInterestRateParts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [collateralInterestRateParts, leverage, mineralBonus])

  const minCollateralization = riskParams?.minCollateralization ?? DEFAULT_MIN_COLLATERALIZATION
  const strategyHealth = calculateStrategyHealth(
    supplyAmounts,
    borrowAmounts,
    marketRiskInfoMap,
    minCollateralization,
    fiatValueMap,
  )

  const { borrowInterestRate: newBorrowRate } = useMemo(() => {
    if (!borrowAmount) {
      return { borrowInterestRate: undefined }
    }
    return getNewInterestRateWithSlippage(
      borrowAmount,
      borrowAmount.multiply(ZERO_FRACTION),
      interestRateMap,
      tokenAddressToMarketIdMap,
      marketTotalWeiMap,
      riskParams,
      showYieldAsApr,
    )
  }, [borrowAmount, interestRateMap, marketTotalWeiMap, riskParams, showYieldAsApr, tokenAddressToMarketIdMap])

  const { supplyInterestRate: newSupplyRate } = useMemo(() => {
    if (!zapOutputValue) return { supplyInterestRate: undefined }
    return getNewInterestRateWithSlippage(
      zapOutputValue.multiply(ZERO_FRACTION),
      zapOutputValue,
      interestRateMap,
      tokenAddressToMarketIdMap,
      marketTotalWeiMap,
      riskParams,
      showYieldAsApr,
    )
  }, [interestRateMap, marketTotalWeiMap, riskParams, showYieldAsApr, tokenAddressToMarketIdMap, zapOutputValue])

  const calculatedNewStrategyInterestRate = useStrategyInterestRate(
    supplyAmounts,
    borrowAmounts,
    fiatValueMap,
    interestRateMap,
    totalYieldSelected,
    false,
    strategy.includeRates,
    useMemo(() => (newSupplyRate ? [newSupplyRate] : undefined), [newSupplyRate]),
    useMemo(() => (newBorrowRate ? [newBorrowRate] : undefined), [newBorrowRate]),
  )

  const parsedRate =
    parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? formatAmount(interestRate, 2) : '0.00%'

  const projectedProfit = useMemo(() => {
    return parsedInputValue && (interestRate || calculatedNewStrategyInterestRate)
      ? parsedInputValue.asFraction.multiply(
          (calculatedNewStrategyInterestRate
            ? calculatedNewStrategyInterestRate.asFraction
            : interestRate?.asFraction) ?? ZERO_FRACTION,
        )
      : ZERO_FRACTION
  }, [calculatedNewStrategyInterestRate, interestRate, parsedInputValue])
  const projectedProfitLoaded = parseFloat(projectedProfit?.denominator.toString() ?? '0') > 0

  const projectedProfit30DayAvg = useMemo(() => {
    return parsedInputValue && historicalInterestRate
      ? parsedInputValue?.asFraction.multiply(historicalInterestRate?.asFraction ?? ZERO_FRACTION)
      : ZERO_FRACTION
  }, [historicalInterestRate, parsedInputValue])
  const projectedProfit30DayLoaded = parseFloat(projectedProfit30DayAvg?.denominator.toString() ?? '0') > 0
  const insufficientBalance = useMemo(() => {
    if (strategyStage > StrategyStage.OPEN) return false
    return (
      (collateralBalance && collateralBalance.equalTo(ZERO_FRACTION)) ||
      (parsedInputValue ?? ZERO_FRACTION).greaterThan(collateralBalance ?? ZERO_FRACTION)
    )
  }, [strategyStage, parsedInputValue, collateralBalance])
  const expirationDateParsed = expirationDate ? new Date(expirationDate) : undefined
  const showExpirationWarning = expirationDateParsed
    ? expirationDateParsed.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false

  const showSupplyCapError = useMemo(() => {
    const totalSupplyWei = marketTotalWeiMap[collateralToken.address ?? '']?.supplyLiquidity
    const supplyMaxWei = marketRiskInfoMap[collateralToken?.address ?? '']?.supplyMaxWei
    if (!supplyMaxWei || !totalSupplyWei || supplyMaxWei.equalTo(ZERO_FRACTION) || !parsedInputValue) {
      return false
    }
    return zapOutputValue ? totalSupplyWei.add(zapOutputValue).greaterThan(supplyMaxWei) : undefined
  }, [marketTotalWeiMap, collateralToken.address, marketRiskInfoMap, parsedInputValue, zapOutputValue])

  const showInsufficientLiquidityError = useMemo(() => {
    const protocolBalance = protocolBalanceInfoMap[debtToken.address ?? '']?.asFraction
    if (!zapOutputValue || !protocolBalance) {
      return false
    }
    return borrowAmount ? protocolBalance.lessThan(borrowAmount.asFraction) : undefined
  }, [protocolBalanceInfoMap, debtToken.address, zapOutputValue, borrowAmount])

  const firstStep = strategyStage === StrategyStage.OPEN && steps > 1
  const isLoadingZapTradeData = !bestZap && !!parsedInputValue && parsedInputValue.greaterThan(0)
  // const openLoading = useMemo(() => {
  //   if (strategyStage === StrategyStage.ZAP && position.supplyAmounts.length !== 0) return true
  //   return false
  // }, [strategyStage, position.borrowAmounts])

  const submitDisabled = useMemo(
    () =>
      !wrongChain &&
      ((isLoadingZapTradeData && !firstStep) ||
        !parsedInputValue ||
        isGeoRestricted ||
        showInsufficientLiquidityError ||
        insufficientBalance ||
        showSupplyCapError),
    [
      wrongChain,
      isLoadingZapTradeData,
      firstStep,
      parsedInputValue,
      isGeoRestricted,
      showInsufficientLiquidityError,
      insufficientBalance,
      showSupplyCapError,
    ],
  )

  const buttonText = useMemo(() => {
    if (strategy.steps) {
      if (strategyStage === StrategyStage.OPEN) return 'Open Position and Deposit'
      else if (strategyStage === StrategyStage.ZAP) return 'Zap and Loop'
    }
    return 'Create Position'
  }, [strategyStage, strategy.steps])

  const relativeSlippage = bestZap?.priceImpact.multiply(leverage - 1)

  return (
    <ModalInner>
      <Column>
        <Header
          strategy={strategy}
          totalYield={totalYield}
          showExpirationWarning={showExpirationWarning}
          expirationDate={expirationDate}
          partsFormatted={partsFormatted}
          health={strategyHealth}
        />
        <YieldBreakdown
          collateralInterestRateParts={collateralInterestRateParts}
          collateralLendingYield={collateralLendingYield}
          collateralTotalYield={collateralTotalYield}
          debtInterestRate={debtInterestRate}
          totalYield={totalYield}
          debtToken={debtToken}
          showYieldAsApr={showYieldAsApr}
          strategy={strategy}
          leverage={leverage}
          newSupplyRate={newSupplyRate}
          newBorrowRate={newBorrowRate}
        />
        <StrategyDescription showDescription={showDescription} strategy={strategy} />
      </Column>
      <Column right>
        {!stepsData ? (
          <>
            <Title>Deposit Amount</Title>
            <BalanceRow>
              <BalanceWrapper>
                {t('dolomiteBalanceText')}
                {` `}
                <Balance>{formatAmount(collateralBalance)}</Balance>
                {changedBalanceValue && collateralBalance && (
                  <NewValueWrapper
                    isNegative={changedBalanceValue.asFraction.lessThan(collateralBalance.asFraction ?? ZERO_FRACTION)}
                  >
                    <ArrowRight />
                  </NewValueWrapper>
                )}
                <NewAmountWrapper>
                  {changedBalanceValue && collateralBalance && (
                    <NewValueWrapper
                      isNegative={changedBalanceValue.asFraction.lessThan(
                        collateralBalance.asFraction ?? ZERO_FRACTION,
                      )}
                    >
                      <NewValue error={changedBalanceValue.lessThan(ZERO_FRACTION)}>
                        {formatAmount(changedBalanceValue)}
                      </NewValue>
                    </NewValueWrapper>
                  )}
                  <Ticker>{collateral}</Ticker>
                </NewAmountWrapper>
              </BalanceWrapper>
              <MaxButton onClick={setMax}>Max</MaxButton>
            </BalanceRow>
            <CurrencyInputPanel
              currency={collateralToken}
              label={''}
              value={inputValue ?? ''}
              disabled={wrongChain || strategyStage > StrategyStage.OPEN}
              onUserInput={handleTypeInput}
              showMaxButton={false}
              disableMaxButton={true}
              isForTrading={false}
              id={'create-position-modal-currency-input'}
              hideLabelRow
            />
            <>
              <DepositButtons visible={insufficientBalance ?? false}>
                <WarningRoundedIcon />
                <SubText>You have insufficient {collateral} on Dolomite. Deposit or trade to get more.</SubText>
                <DepositButton onClick={() => setDepositModalOpen(true)}>Deposit</DepositButton>
                <DepositButton onClick={() => setSwapModalOpen(true)}>Swap</DepositButton>
              </DepositButtons>
            </>
          </>
        ) : (
          <WarningTitle>Finish opening your strategy</WarningTitle>
        )}
        <NewPositionInfo>
          <Title>
            New Position
            <YieldSelector>
              <StyledTooltip title={'Toggle yields including external rewards'}>
                <FilterSelect onClick={() => setTotalYield(!totalYield)} middle>
                  <FilterSelector active={totalYield}>
                    <StyledAutoAwesomeIcon />
                  </FilterSelector>
                  <FilterSelector active={!totalYield}>
                    <StyledNoneIcon />
                  </FilterSelector>
                </FilterSelect>
              </StyledTooltip>
              <FilterSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)} right>
                <FilterSelector active={showYieldAsApr}>APR</FilterSelector>
                <FilterSelector active={!showYieldAsApr}>APY</FilterSelector>
              </FilterSelect>
            </YieldSelector>
          </Title>
          <NewPosition>
            <Yield isNegative={parsedRate.charAt(0) === '-'}>
              {parsedInputValue ? (
                newBorrowRate && newSupplyRate && calculatedNewStrategyInterestRate ? (
                  calculatedNewStrategyInterestRate.toFixed(2) + '%'
                ) : (
                  <InlineSpinnerWrapper>
                    <CircularProgress />
                  </InlineSpinnerWrapper>
                )
              ) : parsedRate.charAt(0) === '-' ? (
                <StyledTooltip
                  title={`This strategy has a negative yield. This may be because the strategy prioritizes rewards earned or leveraged exposure rather than yield, or it may be a result of changes in borrow and supply rates. Review the strategy to make sure it fits your needs.`}
                  placement={'top'}
                >
                  <>{parsedRate}</>
                </StyledTooltip>
              ) : (
                parsedRate
              )}
              <span>{showYieldAsApr ? t('apr') : t('apy')}</span>
            </Yield>
            {newBorrowRate && newSupplyRate && calculatedNewStrategyInterestRate && (
              <UpdatedYieldExplainer>
                Rate updated to reflect the changes in borrow and supply rates from your deposit{' '}
                <StyledTooltipWithIcon
                  tooltipText={
                    'The rate earned on assets supplied and the rate paid for assets borrowed on Dolomite are based on the amount being supplied and borrowed, and can change when more assets are supplied or borrowed. The updated rate being displayed reflects how the supply and borrow rates will change based on a deposit amount of the size entered.'
                  }
                />
              </UpdatedYieldExplainer>
            )}
            <AssetList>
              <AssetTitle small>Collateral</AssetTitle>
              {strategy.collateralAssets.map((asset: Token, i: number) => (
                <Asset key={i} small>
                  <TokenLogo small>
                    <img
                      src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                      alt={`${cleanCurrencySymbol(asset)} logo`}
                    />
                  </TokenLogo>
                  {cleanCurrencySymbol(asset)}
                  <TokenValue loading={loadingZap ? true : undefined}>
                    {/* TODO - only designed for a single collateral and debt asset */}
                    <Large>{parsedInputValue && zapOutputValue && '~'}</Large>
                    {zapOutputValue && parsedInputValue
                      ? formatAmount(parsedInputValue?.add(zapOutputValue))
                      : '0.000000'}
                  </TokenValue>
                </Asset>
              ))}
            </AssetList>
            <AssetList rightModal>
              <AssetTitle right small>
                Debt
              </AssetTitle>
              {strategy.debtAssets.map((asset: Token, i: number) => {
                return (
                  <Asset key={i} small>
                    <TokenValue right loading={loadingZap ? true : undefined}>
                      {borrowAmount ? formatAmount(borrowAmount) : '0.000000'}
                    </TokenValue>
                    <TokenLogo small>
                      <img
                        src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                        alt={`${cleanCurrencySymbol(asset)} logo`}
                      />
                    </TokenLogo>
                    {cleanCurrencySymbol(asset)}
                  </Asset>
                )
              })}
            </AssetList>
          </NewPosition>
          {/*<InfoRow>*/}
          {/*  <div>Expiration</div>*/}
          {/*  <div>{cleanCurrencyName(collateralToken)?.split(' ')[2]}</div>*/}
          {/*</InfoRow>*/}
          {/*<ProjectedProfitWrapper>
           <Title>Projected Profit</Title>
           <ProjectedProfitDescription>
           Profit projections based on your deposit and currently available historical yield information.
           </ProjectedProfitDescription>
           <BasedOnWrapper>
           <BasedOnLine />
           <BasedOnText>Based On</BasedOnText>
           </BasedOnWrapper>
           <ProjectedProfitHeader>
           <ProjectedProfitPeriod>Period</ProjectedProfitPeriod>
           <ProjectedProfitCurrent>Current Yield</ProjectedProfitCurrent>
           <ProjectedProfitAverage>30 Day Average</ProjectedProfitAverage>
           </ProjectedProfitHeader>
           <ProjectProfitTable>
           <ProjectedProfitRow>
           <ProjectedProfitPeriod>1 Day</ProjectedProfitPeriod>
           <ProjectedProfitCurrent>
           {projectedProfitLoaded ? formatAmount(projectedProfit.divide(365)) : '-'} <span>{collateral}</span>
           </ProjectedProfitCurrent>
           <ProjectedProfitAverage>
           {projectedProfit30DayLoaded ? formatAmount(projectedProfit30DayAvg.divide(365)) : '-'}{' '}
           <span>{collateral}</span>
           </ProjectedProfitAverage>
           </ProjectedProfitRow>
           <ProjectedProfitRow>
           <ProjectedProfitPeriod>1 Week</ProjectedProfitPeriod>
           <ProjectedProfitCurrent>
           {projectedProfitLoaded ? formatAmount(projectedProfit.divide(52)) : '-'} <span>{collateral}</span>
           </ProjectedProfitCurrent>
           <ProjectedProfitAverage>
           {projectedProfit30DayLoaded ? formatAmount(projectedProfit30DayAvg.divide(52)) : '-'}{' '}
           <span>{collateral}</span>
           </ProjectedProfitAverage>
           </ProjectedProfitRow>
           <ProjectedProfitRow>
           <ProjectedProfitPeriod>30 Days</ProjectedProfitPeriod>
           <ProjectedProfitCurrent>
           {projectedProfitLoaded ? formatAmount(projectedProfit.divide(365).multiply(30)) : '-'}{' '}
           <span>{collateral}</span>
           </ProjectedProfitCurrent>
           <ProjectedProfitAverage>
           {projectedProfit30DayLoaded ? formatAmount(projectedProfit30DayAvg.divide(365).multiply(30)) : '-'}{' '}
           <span>{collateral}</span>
           </ProjectedProfitAverage>
           </ProjectedProfitRow>
           </ProjectProfitTable>
           </ProjectedProfitWrapper>*/}
        </NewPositionInfo>
        {/*<InfoRow>*/}
        {/*  <div>Liquidated</div>*/}
        {/*  <div></div>*/}
        {/*</InfoRow>*/}
        {steps > 1 && (
          <Steps>
            <StrategyStep expanded={strategyStage === StrategyStage.OPEN}>
              <StrategyProgress>
                <Circle complete={strategyStage > StrategyStage.OPEN}>
                  {strategyStage === StrategyStage.OPEN ? '1' : <Checkmark />}
                </Circle>
                <ProgressLine complete={strategyStage > StrategyStage.OPEN} />
              </StrategyProgress>
              <StrategyText>
                Open and Deposit
                <StepSubtitle visible={strategyStage === StrategyStage.OPEN}>
                  {`Opening a borrow position and depositing ${
                    parsedInputValue ? formatAmount(parsedInputValue) : '0.00'
                  } ${collateral} as collateral`}
                </StepSubtitle>
              </StrategyText>
            </StrategyStep>
            <StrategyStep expanded={strategyStage === StrategyStage.ZAP}>
              <StrategyProgress>
                <Circle complete={strategyStage > StrategyStage.ZAP}>
                  {strategyStage < StrategyStage.COMPLETE ? '2' : <Checkmark />}
                </Circle>
                {steps > 2 && <ProgressLine complete={strategyStage > StrategyStage.ZAP} />}
              </StrategyProgress>
              <StrategyText>
                Zap and Loop
                <StepSubtitle visible={strategyStage === StrategyStage.ZAP}>
                  {`Zapping ${zapBorrowAmount ? formatAmount(zapBorrowAmount) : '0.00'} ${debt} into ${
                    zapOutputValue ? formatAmount(zapOutputValue) : '0.00'
                  } ${collateral} and adding into the position's collateral`}
                </StepSubtitle>
              </StrategyText>
            </StrategyStep>
          </Steps>
        )}
        {(strategy?.collateralAssets[0]?.name?.includes('GM: ') ||
          strategy?.collateralAssets[0]?.name?.includes('GLV: ')) &&
          strategy.steps &&
          strategyStage === StrategyStage.OPEN && (
            <GmFeeExplainerWrapper>
              <GmFeeExplainer expanded={true} visible={true} />
            </GmFeeExplainerWrapper>
          )}
        {(showInsufficientLiquidityError || showSupplyCapError) && (
          <ErrorMessage>
            {showSupplyCapError
              ? `Cannot execute strategy, ${collateral} will go over its supply cap on Dolomite.`
              : showInsufficientLiquidityError
              ? `Cannot execute strategy, ${debt} has insufficient liquidity on Dolomite.`
              : undefined}
          </ErrorMessage>
        )}
        <SubmitButtonWrapper>
          <SubmitButton onClick={onConfirm} wrongChain={wrongChain || isGeoRestricted} disabled={submitDisabled}>
            {wrongChain && strategy.chain ? (
              `Change to ${NETWORK_LABELS[strategy.chain]}`
            ) : isGeoRestricted ? (
              `${cleanCurrencySymbol(
                isGeoRestrictedCollateral ? collateralToken : debtToken,
              )} is restricted in your country`
            ) : isAttemptingTx || isTxPending ? (
              <CircularProgress />
            ) : (
              buttonText
            )}
          </SubmitButton>
        </SubmitButtonWrapper>
        <BorrowTradeDetails
          bestZap={bestZap}
          isLoading={!bestZap && isLoadingZapTradeData}
          refreshZapData={refreshZapData}
          showError={!!zapError}
          open={true}
          small={true}
          strategy={true}
          loadingDisabled={!bestZap && !isLoadingZapTradeData}
          priceImpact={bestZap?.priceImpact}
          relativeSlippage={relativeSlippage}
        />
        {!!borrowAmount && zapOutputValue && (
          <AdvancedDescriptionWrapper>
            {`Will borrow ${formatAmount(borrowAmount)} ${debt}, trade that ${debt} into ${formatAmount(
              parsedInputValue?.add(zapOutputValue),
            )} ${collateral}, and then deposit that ${collateral} into the position as collateral.`}
          </AdvancedDescriptionWrapper>
        )}
      </Column>
      <DepositWithdrawModal
        open={isDepositModalOpen}
        close={() => setDepositModalOpen(false)}
        presetCurrency={collateralToken}
      />
      <ZapModal open={isSwapModalOpen} closeZap={() => setSwapModalOpen(false)} presetToken={collateralToken} />
    </ModalInner>
  )
}

function CreatePositionModalWrapper({
  strategy,
  isOpen,
  closeModal,
  interestRatePair,
  totalYieldSelected,
  addOpeningPosition,
  transactionLoading,
  stepsData,
}: CreatePositionModalProps) {
  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 960
  return (
    <Modal isOpen={isOpen} onDismiss={closeModal} maxHeight={90} maxWidthPx={850} minWidthPx={isMobile ? 370 : 850}>
      {!isOpen || !strategy ? (
        <></>
      ) : (
        <CreatePositionModal
          strategy={strategy}
          isOpen={isOpen}
          closeModal={closeModal}
          interestRatePair={interestRatePair}
          totalYieldSelected={totalYieldSelected}
          addOpeningPosition={addOpeningPosition}
          transactionLoading={transactionLoading}
          stepsData={stepsData}
        />
      )}
    </Modal>
  )
}

export default CreatePositionModalWrapper
